<template>
	<div class="bg-F7F7F7">
		<div class="center">
			<div class="w1200 justify-content-center-between h68">
				<span class="fz20 co-333333">{{title}}</span>
				<div class="center sort cure-pointer relative" @click="pullClick">
					<span class="fz14 co-010085">{{titleListfont}}</span>
					<img class="pull-pic w100 marl4 display-block" :class="pull ? 'rotate' : ''" src="@/assets/img/pull.png" alt="">
					<img class="triangle display-block absolute" v-if="pull" src="@/assets/img/triangle.png" alt="" />
					<div class="absolute pull-box" :style="`height: ${pull ? '130px' : '0px'};`">
						<div class="justify-content-center-between paddlr12 h40" v-for="(item, i) in selectList" :key="i" @click.stop="selectChoose(i)">
							<span class="fz14" :class="seletIndex == i ? 'co-010085' : 'co-333333'">{{item}}</span>
							<img class="check_icon display-block" v-if="seletIndex == i" src="@/assets/img/check_icon.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="center w100">
			<div class="w1200">
				<div style="min-height: 1120px;">
				<ClassSelect :list='list'></ClassSelect>
				<loadMore :state="state" loading_icon></loadMore>
				</div>
				<div class="center paddt54">
					<el-pagination background  class="center "  @current-change="handleCurrentChange"
					    layout="prev, pager, next, jumper" :current-page="page"   :total="maxnum" :page-size="size">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { useRoute } from 'vue-router';
	
	export default {
		name: 'GoodClass',
		data() {
			return {
				title: '', // 标题
				pull: false, // 是否下拉
				type: 1, // 1公开课 2软件课 3小组课 4推荐 5免费
				selectList: ['综合排序', '最新', '最热'], // 下拉列表
				seletIndex: 0, // 下拉选中
				state: 1, // 列表加载状态
				page: 1, // 分页
				loading: false, // 接口加载状态
				list: [], // 列表
				dataObj:{
					sort:'',
				},
				dictTotal: 0,
				size: 16,
				classroomlist: [],
				free:"", //免费课程
				reco:"",//推荐课程
				maxnum:1,
				titleListfont:"综合排序"
			}
		},
		created() {
			let type = useRoute().query.type;
			
			
			if (type == 1) {
				this.type = type
				this.title = '公开课'
			} else if (type == 2) {
				this.type = 3
				this.title = '软件课'
			} else if (type == 3) {
				this.type = 2
				this.title = '设计课'
			} 
			else if (type == 4) {
				this.title = '推荐课程';
				this.reco =1
			} 
			else if (type == 5) {
				this.title = '免费课程'
				this.free =1
			} 
		},
		mounted() {
			
			this.getlist(this.type);
		
		},
		methods: {
			getlist(type) {
				if (this.loading || this.state > 1) return;
				let page = parseInt(this.page) + 1;
				this.loading = true;
				this.state = 1;
				let size = 16;
				this.$req({
					method: 'get',
					data: {
						type: 'course',
						is_reco_index:0,
						cate_id:type,
						sort:this.dataObj.sort,
						page: this.page,
						size: size,
						free:this.free,
						reco:this.reco
					},
					url: '/api/course',
					success: res => {
						let list = res;
						if (page == 1) {
							this.list = list;
						} else {
							this.list= this.list.concat(list);
						}
						// this.paging();
						if (list.length > 0) {
						    this.state = 0;
						} else {
						    this.state = 3;
						}
						this.loading = false;
					},
					fail: error => {
						this.state = 3;
					}
				});
				this.$req({
					method: 'get',
					data: {
						type: 'course',
						is_reco_index:0,
						cate_id:this.type,
						sort:this.dataObj.sort,
						free:this.free,
						reco:this.reco,
						show_page:1,
						
					},
					url: '/api/course',
					success: res => {
						console.log(res,'总条数')
						this.maxnum =res;
					},
					fail: error => {
						
					}
				});
			},
			// 点击下拉
			pullClick() {
				this.pull = !this.pull
			},
			// 假分页
			paging() {
			    let all = this.classroomlist;
			    let size = this.size;
			    let index = (this.page - 1) * size;
			    let arr = [];
			    for (let i = 0; i < size; i++) {
			        if (index < all.length) {
			            arr.push(all[index])
			        }
			        index++;
			    }
			    this.list = arr;
			},
			// 下拉选中
			selectChoose(e) {
				this.seletIndex = e
				this.titleListfont = this.selectList[this.seletIndex]
				this.dataObj.sort = e+1
				this.state = 0;
				this.page=1;
				this.list=[];
				this.getlist(this.type)
				this.pullClick();
			},
			handleCurrentChange(val) {
			    this.page = val;
			   this.list = [];
			   this.loading = false;
			   this.getlist();
			    document.documentElement.scrollTop = document.body.scrollTop = 400;
			},
		}
	}
</script>

<style lang="less" scoped>
	/* 排序 */
	.sort {
		width: 90rpx;
		height: 28px;
		border: 1px solid #010085;
		border-radius: 3px;
		padding: 0 8px;
		
		.pull-pic {
			width: 14px;
			height: 14px;
			transition: all 0.3s;
		}
		
		.rotate {
			transform: rotate(180deg);
		}
		
		.triangle {
			width: 27px;
			height: 15px;
			top: 28px;
			right: 0;
			z-index: 100;
		}
		
		.pull-box {
			width: 140px;
			background: #FFFFFF;
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
			top: 43px;
			border-radius: 8px;
			left: 0;
			overflow: hidden;
			transition: all 0.3s;
		z-index: 100;
			.check_icon {
				width: 15px;
				height: 15px;
			}
		}
	}
</style>
